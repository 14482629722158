export const API = {
  //Auth
  signUp: '/v1/auth/register',
  login: '/v1/auth/login',
  verify_otp: '/v1/auth/verify-otp',
  verifyLink: 'v1/tenants/verify-link',
  verify_invitation_link: 'v1/invitation/verify-link',
  forgot_password: '/v1/auth/forgot-password',
  reset_password: '/v1/auth/reset-password',
  verify_email: '/v1/auth/verify-email',
  resend_otp: '/v1/auth/resent-otp',
  refresh_token: '/v1/auth/refresh-tokens',
  sendMobileOtp: '/v1/auth/mobile-otp',
  verifyMobileOtp: '/v1/auth/verify-mobile-otp',

  channel: '/v1/widget/channels',
  subbed_channel: '/v1/dashboard/channels/summary',

  //User management
  users: 'v1/tenants/users',
  user_list: 'v1/users',
  remove_admin_user: '/v1/users/admin',
  add_user: '/v1/invitation',
  revoke_user: 'v1/users/revoke',

  add_tenant_user: 'v1/tenants',

  // Channel Authentication
  get_auth_url: '/v1/channels/auth',
  get_auth_token: '/v1/channels/token',
  get_accounts: '/v1/channels/accounts',
  channel_permission_list: '/v1/user-channels/permissions',
  channel_count: 'v1/user-channels/count',

  // Workspace
  workspace_list: 'v1/workspaces', // old workspace list: for workspace-detailed, workspace updations
  workspace_summary: 'v1/workspaces/workSpaceSummary', //new workspace list: for workspace display at dashboard
  workspace_detailed: '/v1/workspaces',
  workspace_channel_accounts_list: '/v1/user-channels/accounts',

  // Channel view
  channel_permission_detail: '/v1/user-channels/user-list',
  edit_channel_view_permission: '/v1/channels/users',
  get_channel_roles: '/v1/channels/roles',
  remove_user: '/v1/channels/users',
  request_to_remove: '/v1/user-channels/request-remove',

  // Viewers
  listUsers: '/v1/tenants/users',
  removeViewers: '/v1/workspaces/viewers',
  // Search
  search: '/v1/search',
  individualSearch: '/v1/user-channels',
  pageAccount: '/v1/user-channels/page',
  accountUser: '/v1/user-channels/accountUser',

  //payment
  checkoutSession: '/v1/stripe/createCheckoutSession',
  addSubscription: '/v1/stripe/addSubscriptionDetails',
  getSubscription: '/v1/stripe/getSubscriptionDetails',
  cancelSubscription: '/v1/stripe/cancelSubscription',
  updateSubscription: '/v1/stripe/updatePayment',
  getPriceDetails: '/v1/stripe/subscriptionAmount',
  getInvoiceDetails: '/v1/stripe/listInvoice',
  addAdditionalLicense: '/v1/stripe/additional-seat',
  //Profile
  updateUser: '/v1/users',
  uploadProfileImage: '/v1/files',

  //ChannelProfile
  getChannelProfiles: '/v1/channels/channel-users',

  //Notification
  get_notification: '/v1/notifications',

  // Reports
  generateReport: '/v1/report/accounts',
  distributeReport: 'v1/report/distribute',
  listDistributeReport: '/v1/report/distributeReports',

  //Alerts
  getAlerts: '/v1/alerts',

  //SuperAdmin
  getUsers: '/v1/user-access',
  blockUser: '/v1/user-access/block',
  unblockUser: '/v1/user-access/unblock',
  postMessage: '/v1/messages',
  readMessage: '/v1/messages/read',
  getAllUsers: '/v1/admin/users',
  deleteUser: '/v1/admin/user',
  deleteTenant: '/v1/admin/tenant',
};
