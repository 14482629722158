/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import FilterReorderingDP from '../../components/FilterReorderingDP';
import IndividualChannel from './components/IndividualChannel';
import BreadCrumb from '../../components/BreadCrumb';
import CommonDashboard from '../../components/CommonDashboard';
import NoData from '../../components/Nodata';
import Loader from '../../components/Loader';
import { subscriptionPlans } from '../../constants/constants';
import { navData, textConstants, channelNames } from './constants';
import useAuth from '../../customHooks/useAuth';
import { useToastBox } from '../../customHooks/useToastBox';
import { processChannelList } from './helper';
import { getChannelCount, getChannelPermissionList } from '../../services/userPermissionChannelService';
import Icons from '../../assets/Icons';
import classes from './channel.module.scss';
import { getUserInfo } from '../../utils/getUserInfo';

const UserPermissionChannel = () => {
  let subscriptionType;
  const location = useLocation();
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterDP, setFilterDP] = useState(false);
  const [channelMobileSelection, setChannelMobileSelection] = useState(0);
  const [channelPageList, setChannelPageList] = useState([]);
  const [channelFilter, setChannelFilter] = useState({});
  const [channelCount, setChannelCount] = useState(0);
  const { auth } = useAuth();

  const fromSearch = location?.state?.search;

  sessionStorage.setItem('page', 'Permission');

  const { handleErrorToast } = useToastBox();
  const navigate = useNavigate();

  navData[0].pathHistory[0].title = auth.user?.user?.name;
  navData[0].pathHistory[1].title = auth.channelCount
    ? auth.channelCount === 1
      ? `${auth.channelCount} Channel`
      : `${auth.channelCount} Channels`
    : '-';

  const onFilterApply = (data, company) => {
    setChannelPageList(data);
    setChannelFilter(company);
  };

  const getData = async () => {
    let [channelCount, permissionList] = await Promise.all([
      getChannelCount(),
      getChannelPermissionList(auth.user?.user?.email),
    ]).catch(err => {
      handleErrorToast(err.message);
      setIsLoading(false);
    });
    setIsLoading(false);
    navData[0].pathHistory[1].title = `${channelCount.data} Channels`;
    setChannelCount(channelCount.data);
    setChannelPageList(processChannelList(permissionList.data));
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <CommonDashboard classList={classes.userPermissionWrapper} hideHeader={false}>
      {isLoading && <Loader />}
      <BreadCrumb navData={navData} channelCount={channelCount} prevPage={fromSearch ? '/search-results' : null}>
        <div className={classes.actionGroup}>
          <div className={classes.actionDP}>
            <button
              aria-label="Filter"
              className="btn-outline"
              onClick={() => setFilterDP(true)}
              data-testid="filter-button"
            >
              <span>{textConstants.filter}</span>
            </button>
            {filterDP && <FilterReorderingDP data={channelPageList} setFilter={setFilterDP} onApply={onFilterApply} />}
          </div>
          {!editMode && subscriptionType !== subscriptionPlans.individual ? (
            <button
              aria-label="Edit"
              className="btn-outline disabled:opacity-90 "
              onClick={() => setEditMode(true)}
              data-testid="edit-button"
              disabled
            >
              <span>{textConstants.edit}</span>
            </button>
          ) : subscriptionType !== subscriptionPlans.individual ? (
            <button
              aria-label="Save Changes"
              className="btn-primary-1"
              onClick={() => setEditMode(false)}
              data-testid="save-button"
            >
              <span>{textConstants.save}</span>
            </button>
          ) : null}
        </div>
      </BreadCrumb>
      <div className={classes.coreConsole}>
        <div className={classes.pageHeaderMobWrapper}>
          {channelPageList.length !== 0 &&
            channelPageList.map((data, index) => {
              if (data.checked) {
                return (
                  <div
                    className={`${classes.channelCardHeader} ${channelMobileSelection === index && classes.selected}`}
                    key={`individualChannelHeader__${index}`}
                    onClick={() => setChannelMobileSelection(index)}
                  >
                    <div className={classes.logo}>
                      <img src={data.logo} alt={data.channelName} />
                    </div>
                    <div className={classes.channelTitleWrapper}>
                      <div className={classes.channelTitle}>{data.channelName}</div>
                      <div className={classes.channelPageCount}>
                        {data.pagesDetails.length}{' '}
                        {data.channel === channelNames.facebookPages || data.channel === channelNames.linkedinPages
                          ? textConstants.pages
                          : textConstants.accounts}
                      </div>
                    </div>
                    <div
                      className={classes.externalLink}
                      onClick={() => {
                        navigate('/userPermissionChannelView', {
                          state: {
                            channelName: data.channelName,
                            channelType: data.channel,
                            channelProvider: data.provider,
                          },
                        });
                      }}
                    >
                      <Link>
                        <Icons.Export />
                      </Link>
                    </div>
                  </div>
                );
              }
            })}
        </div>
        {channelPageList.length != 0 ? (
          channelPageList.map((element, index) => {
            if (element.checked) {
              return (
                <>
                  <IndividualChannel
                    itemIndex={index}
                    data={element}
                    key={`individualChannel__${index}`}
                    editMode={editMode}
                    selectedCard={channelMobileSelection}
                    filter={channelFilter}
                    navigate={navigate}
                  />
                </>
              );
            }
          })
        ) : !isLoading ? (
          <div className={classes.noRecords}>
            <NoData message={textConstants.noDataMessage} showImage={true} />
          </div>
        ) : null}
      </div>
    </CommonDashboard>
  );
};
export default UserPermissionChannel;
