const config = {
  DEVELOPMENT: {
    //original Key
    REACT_APP_RECAPTCHASITEKEY: '6Lf6VzwjAAAAAMFGRm8B-EEuo-KD6LqyD06K6eAE',

    //Testing Key
    // REACT_APP_RECAPTCHASITEKEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',

    //Develop Endpoint
    REACT_APP_HYDRA_BASE_URL: 'https://dev-api.hihydra.com',

    //Localhost Endpoint
    // REACT_APP_HYDRA_BASE_URL: 'http://localhost:3001',

    //QA Endpoint
    //REACT_APP_HYDRA_BASE_URL: 'https://qa-api.hihydra.com',

    SOCKET_IO_BASE_URL: 'https://dev-api.hihydra.com',

    // SOCKET_IO_BASE_URL: 'http://localhost:3001',
  },
};

export default config;
